import {
  BookmarkIcon,
  CogIcon,
  CreditCardIcon,
  DiscordIcon,
  ExitIcon,
  PendingIcon,
} from '~/components/icons';
import { GenreIconPicker } from '~/components/shared';
import type {
  ButtonUnionProps,
  DropdownOption,
  DropdownProps,
} from '~/components/ui';
import { Role, type AuthUser } from '~/modules/api';
import { isEditor, isMember, link } from '~/utils';
import { GENRES } from './genres';

interface NavItemCommon {
  label: string;
  variant?: ButtonUnionProps['variant'];
  trackable?: boolean;
}
interface NavItemDropdown extends NavItemCommon {
  href?: undefined;
  options: DropdownOption[];
}
interface NavItemLink extends NavItemCommon {
  href: string;
  options?: undefined;
}

type NavItem = NavItemLink | NavItemDropdown;

export const getHeaderNavItems = (user?: AuthUser | null): NavItem[] => [
  /*   {
    href: link.toFreeThisWeek(),
    label: 'Free This Week',
  }, */
  {
    href: link.toListByKind({ kind: 'flip' }),
    label: 'Flips',
  },
  {
    href: link.toListByKind({ kind: 'spin' }),
    label: 'Spins',
  },
  {
    label: 'Genres',
    options: GENRES.map(genre => ({
      label: genre.name,
      url: link.toGenre({ genre: genre.slug }),
      prefix: (
        <GenreIconPicker
          class="size-5 shrink-0 text-sky-500"
          genre={genre.slug}
        />
      ),
    })),
  },
  {
    href: link.toCategory({ category: 'trending' }),
    label: 'Popular Now',
  },
  // ...(user
  //   ? []
  //   : [
  //       {
  //         href: link.toMembershipPage(),
  //         label: 'Get Premium',
  //         trackable: true,
  //       },
  //     ]),
];

export const getLoggedOutMenuItems = (): NavItem[] => [
  {
    href: link.toSignInFlow(),
    label: 'Log In',
    variant: 'blur',
  },
  {
    href: link.toSignUpFlow(),
    label: 'Sign Up',
    variant: 'primary',
    trackable: true,
  },
];

export const getLoggedInMenuItems = (
  user?: AuthUser,
): { buttons: NavItem[]; dropdown: DropdownProps['options'] } => {
  const dropdown = [
    {
      prefix: <BookmarkIcon class="h-4 w-5" />,
      label: 'Favorites',
      url: link.toMyAccount({ tab: 'favorites' }),
    },
    {
      prefix: <DiscordIcon class="size-5" />,
      label: 'Join the Discord',
      url: link.toDomainDiscordChannel(),
    },
    ...(isMember(user ? user.roles : []) && !isEditor(user ? user.roles : [])
      ? [
          {
            prefix: <CreditCardIcon />,
            label: 'Manage Membership',
            url: link.toBilling(),
          },
        ]
      : []),

    ...(isEditor(user ? user.roles : [])
      ? [
          {
            prefix: <CogIcon />,
            label: 'Content Validator',
            url: link.toContentValidator(),
          },
          {
            prefix: <PendingIcon />,
            label: 'Pending for Publication',
            url: link.toContentPendingForPublication(),
          },
        ]
      : []),
    {
      prefix: <ExitIcon />,
      label: 'Logout',
      url: link.toLogOut(),
    },
  ];

  return {
    buttons: user?.roles.includes(Role.MEMBER)
      ? []
      : [
          // {
          //   href: link.toMembershipPage(),
          //   label: 'Get Premium',
          //   variant: 'primary',
          //   trackable: true,
          // },
        ],
    dropdown,
  };
};
